@import "https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap";
@import "https://fonts.googleapis.com/css2?family=Exo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap";
/*
***************************
* global ******************
***************************
*/
:root {
  --color-white: #ffffff;
  --color-black80: #333333;
  --color-black50: #7f7f7f;
  --color-black20: #cccccc;
  --color-black10: #e5e5e5;
  --color-orange: #f38f1a;
  --color-orange-hover: #f59d35;
  --color-green: #2babb8;
  --color-green-hover: #c2f7fc;
  --color-green-bcg: #e2f7f8;
}
* {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  padding: 0;
  transition: 200ms linear;
}
h1 {
  font-family: 'Exo';
  font-size: 30px;
  font-weight: 'Bold';
  line-height: 40px;
}
.text1 {
  font-size: 18px;
  font-weight: '700';
  line-height: 24px;
}
.text2 {
  font-size: 16px;
  font-weight: '700';
  line-height: 21px;
}
.text3 {
  font-size: 14px;
  line-height: 19px;
}
.container-box {
  margin: 0 auto;
}
.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.btn {
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 700;
  padding: 12px 24px;
}
.btn:focus {
  outline: none;
}
.btn-orange {
  background-color: var(--color-orange);
  color: var(--color-white);
}
.btn-orange:hover {
  background-color: var(--color-orange-hover);
  cursor: pointer;
}
@media (max-width: 8000px) {
  .container-box {
    width: 1440px;
  }
}
@media (max-width: 1440px) {
  .container-box {
    width: 1140px;
  }
}
@media (max-width: 1140px) {
  .container-box {
    width: 960px;
  }
}
@media (max-width: 960px) {
  .container-box {
    width: 720px;
  }
}
@media (max-width: 720px) {
  .container-box {
    width: 576px;
  }
}
@media (max-width: 576px) {
  .container-box {
    width: 100%;
  }
}
/*
***************************
* menu ********************
***************************
*/
.menu {
  background: transparent;
  height: 85px;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: all ease 0.5s;
  z-index: 2;
}
.menu-color {
  background-color: var(--color-white);
  box-shadow: 0 0 12px #444;
}
.menu nav {
  align-items: center;
  display: flex;
  height: inherit;
  justify-content: space-between;
}
.menu img {
  height: 45px;
}
.menu ul {
  line-height: 70px;
  margin: 0;
  padding: 0;
  text-align: end;
}
.menu ul li {
  display: inline-block;
  list-style: none;
}
.menu ul li button a {
  color: var(--color-white);
  font-size: 16px;
  text-transform: none;
}
.menu ul li button a:hover {
  color: var(--color-white);
}
.menu ul li a {
  color: var(--color-black50);
  display: block;
  font-weight: 700;
  padding: 0 15px;
  text-decoration: none;
  text-transform: uppercase;
}
.menu ul li a:hover{
  color: var(--color-green);
}
.btn-menu-mobile-close {
  height: 30px;
  margin: 16px;
}
.menu-mobile-button {
  align-items: center;
  border: 1px solid var(--color-black50);
  border-radius: 5px;
  display: none;
  height: 30px;
  justify-content: center;
  width: 30px;
  z-index: 1;
}
.menu-mobile-button img {
  width: 20px;
}
.menu-mobile {
  background-color: var(--color-white);
  box-shadow: 0 0 12px #444;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(100%);
  transition: transform 200ms linear;
  width: 100%;
  z-index: 2;
}
.menu-mobile img {
  border: 1px solid var(--color-black50);
  border-radius: 5px;
  height: 30px;
  padding: 1px;
  width: 30px;
}
.menu-mobile span {
  color: var(--color-black80);
  cursor: pointer;
  font-size: 40px;
  height: 60px;
  line-height: 72px;
  text-align: center;
  width: 72px;
}
.menu-mobile span:hover {
  opacity: 0.5;
}
.menu-mobile ul {
  border-top: 1px solid var(--color-black80);
  margin: 0;
  padding: 0;
}
.menu-mobile ul a {
  text-decoration: none;
}
.menu-mobile ul a li {
  border-bottom: 1px solid var(--color-black50);
  color: var(--color-black80);
  font-size: 16px;
  font-weight: 500;
  list-style: none;
  padding: 20px;
  opacity: 0.8;
  text-align: center;
}
.menu-mobile ul a li:hover {
  opacity: 0.5;
}
.menu-mobile-open {
  transform: translateX(0%);
}
@media (max-width: 1140px) {
  .menu ul li a {
    padding: 0 8px;
  }
}
@media (max-width: 720px) {
  .menu {
    height: 60px;
  }
  .menu img {
    height: 30px;
  }
  .menu ul {
    display: none;
  }
  .menu-mobile-button {
    display: flex;
  }
}
@media (max-width: 576px) {
  .menu {
    padding: 0 15px;
  }
}
/*
***************************
* inicio ******************
***************************
*/
.banner {
  background-image: url('home.e4b16166.jpg');
  background-position: center top;
  background-repeat: no-repeat;
  background-size: auto 100%;
  height: 766px;
}
.home {
  margin-top: 177px;
  margin-bottom: 40px;
  position: absolute;
  width: 540px;
}
.home h1 {
  color: var(--color-black80);
  font-family: 'Exo';
  font-size: 40px;
  font-weight: 'bold';
  line-height: 53px;
}
.home h1 span {
  color: var(--color-green);
  font-family: 'Exo';
}
.home p {
  color: var(--color-black80);
  line-height: 29px;
  opacity: 0.8;
  width: 364px;
}
@media (max-width: 960px) {
  .banner {
    height: 600px;
  }
  .home {
    width: 400px;
  }
  .home h1 {
    font-size: 30px;
    line-height: 40px;
  }
  .home p {
    width: 280px;
  }
}
@media (max-width: 720px) {
  .banner {
    height: 500px;
  }
  .home {
    margin-top: 100px;
    width: 300px;
  }

  .home p {
    width: 200px;
  }
}
@media (max-width: 576px) {
  .banner {
    background-position: right top;
    height: 300px;
    margin-top: 200px;
  }
  .home {
    margin-left: 15px;
    margin-top: -100px;
    width: 300px;
  }
}
/*
***************************
* como funciona ***********
***************************
*/
#como-funciona {
  background-color: var(--color-green);
  height: 512px;
  width: 100%;
}
.how {
  display: flex;
}
.how-content-1 {
  flex: 1;
  margin-top: 160px;
  padding-right: 40px;
}
.how-content-1 h1 {
  color: var(--color-white);
  margin-bottom: 40px;
}
.how-content-1 p {
  color: var(--color-white);
  font-size: 18px;
  line-height: 29px;
  width: 460px;
}
.how-content-2 {
  border-left: 1px solid var(--color-white);
  flex: 1;
  margin-top: 130px;
  padding-left: 40px;
}
.how-content-2 h3 {
  color: var(--color-white);
  flex: 1;
  font-family: 'Exo';
  font-size: 20px;
  line-height: 27px;
}
.how-content-2-item {
  align-items: center;
  display: flex;
  margin-bottom: 20px;
}
.how-content-2-item img {
  height: 56px;
  margin-right: 20px;
  width: 56px;
}
.how-content-2-item p {
  color: var(--color-white);
}
@media (max-width: 960px) {
  #como-funciona {
    height: 900px;
  }
  .how {
    flex-direction: column;
  }
  .how-content-1 {
    border-bottom: 1px solid var(--color-white);
    margin-top: 30px;
    padding: 15px;
    padding-bottom: 60px;
  }
  .how-content-1 h1 {
    font-size: 24px;
    line-height: 30px;
    text-align: center;
  }
  .how-content-1 p {
    margin: 0 auto;
    text-align: center;
    width: 100%;
  }
  .how-content-2 {
    border-left: none;
    margin-top: 40px;
    padding: 15px;
  }
  .how-content-2 h3 {
    text-align: center;
  }


}
/*
***************************
* soluções ****************
***************************
*/
.solutions h1 {
  color: var(--color-black80color);
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 60px;
  margin-top: 96px;
  opacity: 0.8;
  text-align: center;
}
.solutions-cards {
  display: flex;
  justify-content: space-between;
}
.solutions-card {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 270px;
}
.solutions-card img {
  height: 86px;
  width: 86px;
}
.solutions-card p {
  color: var(--color-black80);
  opacity: 0.8;
  text-align: center;
}
.solutions-content {
  display: flex;
  margin-bottom: 120px;
  margin-top: 60px;
}
.solutions-content-1 {
  flex: 1;
}
.solutions-content-1 p {
  font-weight: 700;
  text-transform: uppercase;
}
.solutions-content-1 h1 {
  font-size: 24px;
  line-height: 30px;
  margin: 0;
  text-align: start;
  width: 270px;
}
.solutions-content-1 h1 span {
  color: var(--color-green);
}
.solutions-content-1-options button {
  border: 1px solid var(--color-green);
  border-radius: 23px;
  color: var(--color-green);
  font-size: 16px;
  font-weight: 700;
  padding: 15px;
}
.solutions-content-1-options button:focus {
  outline: none;
}
.solutions-content-1-options button:hover {
  cursor: pointer;
}
.solutions-content-1-options button:nth-child(1) {
  background-color: var(--color-green-bcg);
  margin-right: 20px;
}
.solutions-content-1-options button:nth-child(2) {
  background-color: var(--color-white);
}
.solutions-content-1-bar {
  background-color: var(--color-black80);
  margin-bottom: 40px;
  margin-top: 50px;
  height: 8px;
  opacity: 0.8;
  width: 49px;
}
.solutions-content-2 {
  flex: 2;
}
.solutions-content-2 p {
  color: var(--color-black80);
  font-weight: 700;
  opacity: 0.4;
  text-transform: uppercase;
}
.solutions-content-2 img {
  width: 100%;
}
.mobile {
  display: none;
}
@media (max-width: 960px) {
  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }
  .solutions-content {
    flex-direction: column;
    padding: 0 15px;
  }
  .solutions-content-1 h1 {
    width: 100%;
  }
}
@media (max-width: 720px) {
  .solutions-cards {
    align-items: center;
    flex-direction: column;
  }
  .solutions-card {
    margin-bottom: 48px;
  }
}
/*
***************************
* contato *****************
***************************
*/
footer {
  background-color: var(--color-green);
  width: 100%;
}
.contact {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 120px 15px;
}
.contact h1 {
  color: var(--color-white);
  font-size: 30px;
  line-height: 40px;
  text-align: center;
}
.contact p {
  color: var(--color-white);
  margin: 10px auto;
  max-width: 490px;
  text-align: center;
}
form {
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 744px;
  width: 100%;
}
.contact-inputs {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  width: 100%;
}
.contact-input:nth-child(1) {
  margin-right: 8px;
}
.contact-input:nth-child(2) {
  margin-left: 8px;
}
.contact-input {
  position: relative;
  width: 100%;
}
.contact-input input {
  border: none;
  border-radius: 25px;
  color: var(--color-black50);
  padding: 15px;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 16px;
  padding-left: 40px;
  width: inherit;
}
.contact-input input:focus {
  outline: none;
}
.contact-input span {
  left: 15px;
  position: absolute;
  top: 16px;
}
.contact-textarea {
  position: relative;
  width: 100%;
}
.contact-textarea textarea {
  border: none;
  border-radius: 9px;
  color: var(--color-black50);
  font-size: 16px;
  height: 213px;
  line-height: 21px;
  padding: 15px;
  padding-left: 40px;
  width: 100%;
}
.contact-textarea textarea:focus {
  outline: none;
}
.contact-textarea span {
  left: 15px;
  position: absolute;
  top: 16px;
}
.contact-button {
  margin-top: 16px;
  width: 100%;
}
.contact-button button {
  margin-right: auto;
}
@media (max-width: 960px) {
  .contact {
    padding: 60px 15px 120px;
  }
  .contact h2 {
    font-size: 24px;
    line-height: 30px;
  }
  .contact-inputs {
    flex-direction: column;
  }
  .contact-input:nth-child(1) {
    margin-right: 0;
  }
  .contact-input:nth-child(2) {
    margin-left: 0;
  }
}
/*
***************************
* alert *******************
***************************
*/
.alert {
  position: fixed;
  top: 100px;
  right: 15px;
  padding: 15px;
  border-radius: 5px;
  z-index: 10;
  display: none;
}
.alert-red {
  background-color: #f8d7da;
  color: #721c24;
}
.alert-green {
  background-color: #d4edda;
  color: #155724;
}

/*# sourceMappingURL=index.eca3f59f.css.map */
